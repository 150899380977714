import axios from "axios";
import md5 from "js-md5";
import router from "../router/index";
import { Loading, Message } from "element-ui";
// 根据环境不同引入不同api地址
import { baseApi } from "@/config";
// create an axios instance
const service = axios.create({
  baseURL: baseApi, // url = base api url + request url
  timeout: 30000, // request timeout
});
axios.defaults.withCredentials = true;
// request拦截器 request interceptor
service.interceptors.request.use(
  (config) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // console.log(userInfo);
    if (userInfo && userInfo.id && config.params) {
      config.params.session_id = userInfo.session_id;
      config.params.session_number = userInfo.number;
      config.params.device_type = 1;
    }
    if (userInfo && userInfo.id && config.data) {
      config.data.session_id = userInfo.session_id;
      config.data.session_number = userInfo.number;
      config.data.device_type = 1;
    }
    // let a = JSON.stringify(config.data) + "rd_ktm_app";
    if (config.params) {
      let aa = JSON.stringify(config.params) + "rd_ktm_app";
      config.params.signature_data = JSON.stringify(config.params);
      config.params.device_type = 1;
      config.params.signature = md5(aa);
    }
    if (config.data) {
      let a = JSON.stringify(config.data) + "rd_ktm_app";
      config.data.signature_data = JSON.stringify(config.data);
      config.data.device_type = 1;
      config.data.signature = md5(a);
    }
    // config.data.signature = md5(a);
    const token = localStorage.getItem("token");
    let header1 = {
      "Content-Type": "multipart/form-data",
      "Accept-Language": token,
    };
    let header2 = {
      "Accept-Language": token,
    };
    if (config.method == "post") {
      config.headers = header1;
    }
    if (config.method == "get") {
      config.headers = header2;
    }

    config.crossDomain = true;
    config.withCredentials = true;
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);
// respone拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code == 401) {
      localStorage.clear();
      router.push({ name: "login" });
    }
    if (res.code !== 200) {
      // Toast.fail(res.msg);
      Message.error(res.msg || "Error");
      return Promise.reject(res.msg || "Error");
    } else {
      // Toast.clear()
      return res || res.msg;
    }
  },
  (error) => {
    // Toast.clear()

    return Promise.reject(error);
  }
);

/*
截取url中的code
建议使用正则匹配
*/
function getCode() {
  const href = window.location.href;
  let code;
  if (href.indexOf("code") != -1) {
    if (href.indexOf("&") != -1) {
      code = href.split("?")[1].split("&");
      code = code.find((res) => res.indexOf("code") != -1);
    } else {
      code = href.split("?")[1];
    }
    // Vue.prototype.code = code.split('=')[1]
    return code.split("=")[1];
  }
  return null;
}

export default service;
